import * as React from "react";
import User from "../interfaces/User";
import { useNavigate } from "react-router";
import jwt_decode from "jwt-decode";
import { gql, useMutation, useApolloClient } from "@apollo/client";
import { updateLanguageServiceSourceFile } from "typescript";

const REFRESH = gql`
  mutation Mutation {
    refreshSession {
      session {
        jwt
      }
      user {
        id
        email
      }
      success
      message
    }
  }
`;

const AuthContext = React.createContext<{
  authed: boolean | null;
  user: User | null;
  login: () => Promise<boolean>;
  logout: () => Promise<boolean>;
}>({
  authed: false,
  user: null,
  login: () => {
    return Promise.resolve(false);
  },
  logout: () => {
    return Promise.resolve(false);
  },
});

function useAuth() {
  const [authed, setAuthed] = React.useState<boolean | null>(null);
  const [user, setUser] = React.useState<User | null>(null);
  const [refreshSession] = useMutation(REFRESH);
  const client = useApolloClient();
  const navigate = useNavigate();

  const _refreshSession = () => {
    const token = localStorage.getItem("token");
      if (token?.length) {
        try {
          const decoded = jwt_decode(token);
          if (decoded) {
            refreshSession().then(({ data }) => {
              if (data?.refreshSession?.success) {
                setUser(data.refreshSession.user);
                return setAuthed(true);
              }
              setAuthed(false);
            });
            return;
          }
        } catch (e) {
          console.warn("Invalid JWT token");
        }
      }
      setAuthed(false);
      return;
  }

  React.useEffect(() => {
    _refreshSession();
  }, []);

  return {
    authed,
    user,
    login(): Promise<boolean> {
      return new Promise((res) => {
        client.resetStore();

        _refreshSession();

        navigate('/');
        res(true);
      });
    },
    logout(): Promise<boolean> {
      return new Promise((res) => {
        localStorage.removeItem('token');
        navigate("/login", { replace: true });
        client.resetStore();
        setUser(null);
        res(false);
      });
    },
  };
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const auth = useAuth();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export default function AuthConsumer() {
  return React.useContext(AuthContext);
}
