import { Routes, Route, Link } from "react-router-dom";
import Home from "./pages/Home";

import "./App.css";
import PrivateRoute from "./components/PrivateRoute";
import Button from "./components/Button";
import Login from "./pages/Login";
import Verif from "./pages/Verif";

import useAuth from "./hooks/useAuth";
import Register from "./pages/Register";
import {appName} from './libs/Config';

function App() {
  const { user, logout } = useAuth();
  return (
    <div className="bg-blue-50 h-full min-h-screen">
      <div className="flex items-stretch p-5">
        <Link to="/" className="flex-none text-3xl">
          <span className="text-5xl">🕵️</span> {appName}
        </Link>

        <div className="flex-1 text-center text-3xl"></div>

        {user ? (
          <div className="flex-none">
            <span className="mx-2">{user.email}</span>
            <Button variant="secondary" onClick={() => logout()}>Logout</Button>
          </div>
        ) : (
          <div className="flex-none">
            <Link to={"/login"}>
              <Button>Login</Button>
            </Link>
            <Link to={"/register"}>
              <Button variant="primary">Register</Button>
            </Link>
          </div>
        )}
      </div>

      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/verif/:userId/:verifCode" element={<Verif />} />

        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/password-reset" element={<Home />} />

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/plan" element={<Home />} />
          <Route path="/password" element={<Home />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
