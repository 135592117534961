import { HTMLInputTypeAttribute, InputHTMLAttributes, useMemo, useState } from "react";

interface PillSelectorPropsType {
  values: string[];
  selected?: number;
  onChange?: (index: number) => void;
  className?: string;
}

export default function PillSelector(props: PillSelectorPropsType) {

  const length = useMemo(() => props.values.length, [props.values]);
  const [selected, setSelected] = useState(props.selected);

  const onChange = (index: number) => {
    setSelected(index);
    props.onChange && props.onChange(index);
  };

  return (
    <div className={`bg-blue-100 rounded-xl p-2 ${props.className}`}>
      <div className="relative">
        <div
          className={`py-2 rounded-xl bg-white shadow-sm absolute transition-transform transform h-full`}
          style={{
            transform: "translateX(" + (selected || 0) * 100 + "%)",
            width: (100/length) + "%"
          }}
        >&nbsp;
        </div>

        <div className="relative flex w-full h-full">
          {props.values.map((value, index) => (
          <button type="button" key={value} className={`py-2`} style={{width: (100/length) + "%"}} onClick={() => onChange(index)}>
            {value}
          </button>)
          )}
        </div>
      </div>
    </div>
  );
}
