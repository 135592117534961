import { Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";


export default function PrivateRoute() {
  const { authed } = useAuth();

  // If auth isn't initialized yet, don't do anything
  if (authed === null) return <Outlet />;

  return authed ? <Outlet /> : <Navigate to="/login" state={{error: 'Not logged in'}} />;
}
