import { gql, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import Button from "../components/Button";

import InputText from "../components/InputText";

const LOGIN_MUTATION = gql`
  mutation Register($email: String!, $password: String!) {
    register(email: $email, password: $password) {
      success
      message
      session {
        jwt
        user {
          id
          email
        }
      }
    }
  }
`;

type FormValues = {
  email: string;
  password: string;
  password2: string;
};

function Register() {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormValues>();
  const [loginMutation] = useMutation(LOGIN_MUTATION);
  const navigate = useNavigate();

  const onSubmit = async (data: FormValues) => {
    if (data.password !== data.password2) {
      return setError("password", {
        type: "manual",
        message: "Passwords do not match",
      });
    }

    const result = await loginMutation({
      variables: {
        email: `${data.email}`.trim().toLowerCase(),
        password: data.password,
      },
    });
    console.log(result);
    if (!result.data.register.success && result.data.register.message)
      return setError("email", {
        type: "manual",
        message: result.data.register.message,
      });

    if (result.data.register.success) {
      localStorage.setItem("token", result.data.register.session.jwt);
      return navigate("/")
    }
  };

  return (
    <div className="App">
      <div className="max-w-3xl p-4 m-8 mx-auto bg-white rounded-2xl shadow-lg">
        <div className="flex pb-6">
          <h2 className="flex-1 text-left font-semibold">
            Register for a free account
          </h2>
        </div>

        <form className="space-y-6 text-center" onSubmit={handleSubmit(onSubmit)}>
          {!!errors?.email && (
            <p className="text-red-600">{errors.email.message}</p>
          )}
          <InputText
            type="email"
            placeholder="Email address"
            {...register("email")}
          />

          <InputText
            type="password"
            placeholder="Your password"
            {...register("password")}
          />
          <InputText
            type="password"
            placeholder="Repeat your password"
            {...register("password2")}
          />

          <Button variant="primary" className="text-lg">
            Create your free account
          </Button>
        </form>

        <div className="text-center m-4 mt-8">
            <Link to="/login">Already have an account ?</Link>
        </div>
      </div>
    </div>
  );
}

export default Register;
