import BigNumber from "bignumber.js";

import { useMemo } from "react";
import { Monitor } from "../interfaces/Monitor";
import networks from '../libs/Networks';

interface MonitorTextProps {
  monitor: Monitor;
}

function convertValue(value:number, currency:string = 'ETH') {
  const wei = new BigNumber(value);
  return <strong className="font-medium">{wei.dividedBy(1e18).toString()}&nbsp;{currency}</strong>;
}

function formatWallet(monitor: Monitor) {
  const explorerUrl = networks.find(network => network.value === monitor.blockchain)?.explorer.replace('$ADDRESS$', monitor.wallet);
  return <a title={monitor.wallet} className="font-medium" href={explorerUrl} rel="noreferrer" target="_blank">{monitor.wallet.slice(0, 6) + "..." + monitor.wallet.slice(-4)}</a>;
}


export default function MonitorText({ monitor }: MonitorTextProps) {
  const event = useMemo(() => {
    const currency = networks.find((network) => network.value === monitor.blockchain)?.currency;
    const valueText = monitor.value > 0 ? <> of value above {convertValue(monitor.value, currency)}</> : "";

    if (monitor.type === "TRANSACTION_TO") {
      return <>a transaction{valueText} is sent to {formatWallet(monitor)}</>;
    } else if (monitor.type === "TRANSACTION_FROM") {
      return <>a transaction{valueText} is sent from {formatWallet(monitor)}</>
    } else if (monitor.type === "BALANCE_BELOW") {
      return <>the value on wallet {formatWallet(monitor)} is below {convertValue(monitor.value, currency)}</>;
    } else if (monitor.type === "BALANCE_ABOVE") {
      return <>the value on wallet {formatWallet(monitor)} is above {convertValue(monitor.value, currency)}</>;
    }
  }, [monitor]);

  const action = useMemo(() => {
    if (monitor.email) {
      return <>send an email to <a href={`mailto:${monitor.email}`}>{monitor.email}</a></>;
    } else if (monitor.type === "TRANSACTION_FROM") {
      return <>call url <a href={monitor.webhook||""}>{monitor.webhook}</a></>;
    }
  }, [monitor]);

  return (
      <div>
      When {event}, {action}.
      </div>
  );
}
