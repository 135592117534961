import { useMemo } from "react";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;  
  variant?: "primary" | "secondary" | "tertiary" | "outline-primary" | "outline-secondary" | "outline-tertiary";
}


export default function Button({ children, className, ...rest } : ButtonProps) {

  const classNameColor = useMemo(() => {
    if (rest.variant === "primary")
      return "bg-blue-400 text-slate-50 border-blue-400 hover:bg-blue-500 hover:text-slate-100";

    return "bg-slate-50 text-blue-500 border-slate-50 hover:bg-blue-100 hover:text-blue-400";
  }, [rest.variant]);

  return (
    <button
      className={`border px-4 py-2 mx-1 font-medium rounded-lg box-content ${classNameColor} ${className}`}
      {...rest}
    >
      {children}
    </button>
  );
}
