import { gql, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

const VERIF_MUTATION = gql`
  mutation Verif($userId: String!, $verifCode: String!) {
    verifEmail(userId: $userId, verifCode: $verifCode) {
      success
      message
    }
  }
`;

function Verif() {
  const [verifMutation] = useMutation(VERIF_MUTATION);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const params = useParams();

  useEffect(() => {
    const userId = params.userId;
    const verifCode = params.verifCode;

    verifMutation({
      variables: {
        userId,
        verifCode,
      },
    }).then((res) => {
      if (res.data.verifEmail.success) setSuccess(true);
      if (!res.data.verifEmail.success) {
        setSuccess(false);
        setError(res.data.verifEmail.message);
      }
    });
  }, []);

  return (
    <div className="App">
      <div className="max-w-3xl p-4 m-8 mx-auto bg-white rounded-2xl shadow-lg">
        <div className="flex pb-6">
          <h2 className="flex-1 text-left font-semibold">
            Verify your email address
          </h2>
        </div>

        <div className="flex pb-6">
          <div className="flex-1 text-left">
            {success && (
              <div>
                <p>Your email address has been verified.</p>
                <p>You can now <Link to="/login" className="underline">login </Link> to your account.</p>
              </div>
            )}

            {error && (
                <div>
                <p>The verification code is not found. Either you already verified your account, or the link is malformed.</p>
                <p>If you still experience issues with your account, please contact us.</p>
              </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Verif;
