import { gql, useMutation } from "@apollo/client";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, Navigate, useNavigate } from "react-router-dom";

import useAuth from '../hooks/useAuth';

import Button from "../components/Button";
import InputText from "../components/InputText";

const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      success
      message
      session {
        jwt
        user {
          id
          email
        }
      }
    }
  }
`;

const ASK_VERIF_MUTATION = gql`
  mutation AskVerif($email: String!) {
    askVerifEmail(email: $email) {
      success
      message
    }
  }
`;

type FormValues = {
  email: string;
  password: string;
};

function Login() {
  const {
    register,
    handleSubmit,
    setError,
    watch,
    formState: { errors },
  } = useForm<FormValues>();
  
  const { login } = useAuth();

  const [loginMutation] = useMutation(LOGIN_MUTATION);
  const [askVerifMutation] = useMutation(ASK_VERIF_MUTATION);
  const [verifSent, setVerifSent] = useState(false);

  const navigate = useNavigate();

  const askVerif = async () => {
    const res = await askVerifMutation({
      variables: { email: watch('email') },
    });

    if (res.data.askVerifEmail.success) {
      setVerifSent(true);
    }
  };

  const onSubmit = async (data: FormValues) => {
    setVerifSent(false);
    const result = await loginMutation({
      variables: {
        email: `${data.email}`.trim().toLowerCase(),
        password: data.password,
      },
    });

    if (!result.data.login.success && result.data.login.message) {
      let message = result.data.login.message;
      if (result.data.login.message === "User not verified.") {
        message = (
          <p>
            {`${result.data.login.message}\nPlease check your email for verification link (may be in your Spam folder).`}
            <Button onClick={askVerif} type="button">Resend email</Button>
          </p>
        );
      }

      return setError("email", {
        type: "manual",
        message,
      });
    }

    if (result.data.login.success) {
      localStorage.setItem("token", result.data.login.session.jwt);
      return login();
    }
  };

  return (
    <div className="App">
      <div className="max-w-3xl p-4 m-8 mx-auto bg-white rounded-2xl shadow-lg">
        <div className="flex pb-6">
          <h2 className="flex-1 text-left font-semibold">
            Login to your account
          </h2>
        </div>

        <form
          className="space-y-6 text-center"
          onSubmit={handleSubmit(onSubmit)}
        >
          {verifSent && (<p>A verification email has been sent !</p>)}
          {!!errors?.email && (
            <p className="text-red-600">{errors.email.message}</p>
          )}
          <InputText
            type="email"
            placeholder="Email address"
            {...register("email")}
          />

          <InputText
            type="password"
            placeholder="Your password"
            {...register("password")}
          />
          <Button variant="primary" className="text-lg">
            Login
          </Button>
        </form>

        <ul className="text-center m-4 mt-8">
          <li>
            <Link to="/register">No account yet ?</Link>
          </li>
          <li>
            <Link to="/reset">Forgot your password ?</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Login;
