import { gql, useMutation } from "@apollo/client";
import { useState } from "react";
import { FaPause, FaPlay, FaTrash } from "react-icons/fa";
import { Monitor } from "../interfaces/Monitor";
import { MONITORS_QUERY } from "../pages/Home";
import MonitorText from "./MonitorText";


interface MonitorTextProps {
  monitor: Monitor;
}

const PAUSE_MONITOR_MUTATION = gql`
  mutation PauseMonitor($id: ID!) {
    pauseMonitor(id: $id) {
      success
      message
    }
  }
`;

const DELETE_MONITOR_MUTATION = gql`
  mutation DeleteMonitor($id: ID!) {
    deleteMonitor(id: $id) {
      success
      message
    }
  }
`;

export default function MonitorComponent({ monitor }: MonitorTextProps) {
  const [pauseMonitorMutation] = useMutation(PAUSE_MONITOR_MUTATION, { refetchQueries: [MONITORS_QUERY, "Monitors"] });
  const [deleteMonitorMutation] = useMutation(DELETE_MONITOR_MUTATION, { refetchQueries: [MONITORS_QUERY, "Monitors"] });
  const [pausedSuccess, setPausedSuccess] = useState(false);

  function deleteClicked() {
    const confirm = window.confirm("Are you sure you want to delete this monitor?");
    if (!confirm) return;

    deleteMonitorMutation({
      variables: { id: monitor.id },
    }).then((result => {
      if (result.data?.deleteMonitor.message) {
        alert(result.data.deleteMonitor.message);
      }
    }));
  }

  function pauseClicked() {
    pauseMonitorMutation({
      variables: { id: monitor.id },
    }).then((result => {
      if (result.data?.pauseMonitor.message) {
        alert(result.data.pauseMonitor.message);
      }

      setPausedSuccess(true);
      setTimeout(() => setPausedSuccess(false), 3000);
    }));
  }

  return (
    <div
      className="max-w-3xl p-4 m-8 mx-auto bg-white rounded-2xl shadow-lg"
      key={`monitor-${monitor.id}`}
    >
      <div className="flex pb-4">
        <h2 className="flex-1 text-left font-semibold">
          {`Monitor: ${monitor.name}`}
        </h2>

        <div className="flex text-gray-700 space-x-3">
          {monitor.state === 'ENABLED' ? <FaPause onClick={pauseClicked} className="cursor-pointer" /> : <FaPlay onClick={pauseClicked} className="cursor-pointer" /> }
          <FaTrash onClick={deleteClicked} className="cursor-pointer" />
        </div>
      </div>
      <MonitorText monitor={monitor} />
    </div>
  );
}
