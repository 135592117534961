import React from "react";

export interface TextFieldProps
  extends React.PropsWithoutRef<JSX.IntrinsicElements["input"]> {
  error?: string | undefined;
}



const InputText = React.forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => (
  <input
  className="bg-blue-100 rounded-xl p-3 py-4 w-full disabled:cursor-not-allowed disabled:text-gray-500"
  ref={ref}
  {...props}
  />
));

export default InputText;