import { useQuery, gql, useMutation } from "@apollo/client";
import { Listbox, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaCheck, FaChevronDown } from "react-icons/fa";
import Button from "../components/Button";

import InputText from "../components/InputText";
import MonitorComponent from "../components/MonitorComponent";
import PillSelector from "../components/PillSelector";
import { Monitor } from "../interfaces/Monitor";
import useAuth from "../hooks/useAuth";

import networks from "../libs/Networks";

const monitoringTypes = [
  { name: "Transaction to", value: "TRANSACTION_TO" },
  { name: "Transaction from", value: "TRANSACTION_FROM" },
  { name: "Balance below", value: "BALANCE_BELOW" },
  { name: "Balance above", value: "BALANCE_ABOVE" },
];

const notificationTypes = ["Email", "Webhook"];

type FormValues = {
  wallet: string;
  network: { value: string; name: string };
  email: string;
  webhook: string;
  type: string;
  value: number;
  name: string;
};

export const MONITORS_QUERY = gql`
  query Monitors {
    monitors {
      id
      name
      state
      wallet
      value
      blockchain
      type
      webhook
      email
    }
  }
`;

const CREATE_MONITOR_MUTATION = gql`
  mutation CreateMonitor($monitor: MonitorInput!) {
    updateMonitor(monitor: $monitor) {
      success
      message
    }
  }
`;

function Home() {
  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { isDirty },
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      type: monitoringTypes[0].value,
      network: networks[0],
    },
  });

  const { data: monitorsData, loading } = useQuery(MONITORS_QUERY);
  const [createMonitor, { data, loading: createLoading, error }] = useMutation(
    CREATE_MONITOR_MUTATION,
    { refetchQueries: [MONITORS_QUERY, "Monitors"] }
  );

  const [notifSelected, setNotifSelected] = useState(0);
  const [network, setNetwork] = useState(networks[0]);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (user?.email) setValue("email", user?.email || "");
  }, [user]);

  const onSubmit = async (data: any) => {
    setSuccess(false);

    const result = await createMonitor({
      variables: {
        monitor: {
          name: data.name,
          wallet: data.wallet,
          blockchain: data.network.value,
          type: data.type,
          value: data.value,
          webhook: notifSelected === 1 ? data.webhook : undefined,
          email: notifSelected === 0 ? data.email : undefined,
        },
      },
    });

    if (result.data.updateMonitor.success) {
      setSuccess(true);
      setTimeout(() => setSuccess(false), 3000);
      reset();
    } else if (result.data.updateMonitor.message) {
      alert(result.data.updateMonitor.message);
    }
  };

  return (
    <div className="App">
      <div className="max-w-3xl p-4 m-8 mx-auto bg-white rounded-2xl shadow-lg text-center">
        <div className="flex pb-6">
          <h2 className="flex-1 text-left font-semibold">
            New wallet/contract monitor
          </h2>
        </div>

        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
          {success && (
            <div className="border-green-300 bg-green-100 rounded-xl p-2">
              New monitor created !
            </div>
          )}

          <div className="flex bg-blue-100 rounded-xl p-3 py-4 w-full space-x-4">
            <input
              className="bg-transparent flex-1 border-r border-gray-400"
              placeholder="Wallet address: 0xaAbB..."
              required
              {...register("wallet")}
            />

            <Controller
              control={control}
              defaultValue={network}
              name="network"
              render={({ field: { onChange } }) => (
                <Listbox
                  value={network}
                  onChange={(e) => {
                    onChange(e);
                    setNetwork(e);
                  }}
                >
                  <div className="relative flex-2">
                    <Listbox.Button className="relative w-full pl-3 pr-10 text-left bg-blue-100 rounded-lg cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500">
                      <span className="block truncate">{network.name}</span>
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <FaChevronDown
                          className="w-5 h-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 w-full py-1 mt-1 text-left overflow-auto bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {networks.map((network, personIdx) => (
                          <Listbox.Option
                            key={personIdx}
                            className={({ active }) =>
                              `${
                                active
                                  ? "text-blue-700 bg-blue-100"
                                  : "text-gray-900"
                              } cursor-default select-none relative py-2 pl-10 pr-4`
                            }
                            value={network}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={`${
                                    selected ? "font-medium" : "font-normal"
                                  } block truncate`}
                                >
                                  {network.name}
                                </span>
                                {selected ? (
                                  <span
                                    className={`${
                                      active ? "text-blue-600" : "text-blue-600"
                                    } absolute inset-y-0 left-0 flex items-center pl-3`}
                                  >
                                    <FaCheck
                                      className="w-5 h-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              )}
            />
          </div>

          <Transition
            show={isDirty}
            enter="transition-opacity duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="space-y-6">
              <Controller
                control={control}
                defaultValue=""
                name="type"
                render={({ field: { onChange } }) => (
                  <PillSelector
                    values={monitoringTypes.map((v) => v.name)}
                    onChange={(id) => onChange(monitoringTypes[id].value)}
                  />
                )}
              />

              <Transition
                show={watch("type").includes("BALANCE")}
                enter="transition-opacity duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <InputText
                  type="text"
                  placeholder={`Value: 0.01 ${network.currency}`}
                  {...register("value")}
                />
              </Transition>

              <div className="flex justify-center">
                <div className="self-center font-medium pr-5">Notify via:</div>
                <PillSelector
                  values={notificationTypes}
                  className="w-1/3"
                  onChange={(id) => setNotifSelected(id)}
                />
              </div>
              <InputText
                type="email"
                required={notifSelected === 0}
                placeholder="Email address"
                disabled
                style={{ display: notifSelected === 0 ? "block" : "none" }}
                {...register("email")}
              />
              <InputText
                type="url"
                required={notifSelected === 1}
                placeholder="Webhook URL"
                style={{ display: notifSelected === 1 ? "block" : "none" }}
                {...register("webhook")}
              />

              <InputText
                type="text"
                placeholder="Name of your monitor"
                required
                {...register("name")}
              />
            </div>
          </Transition>
          <Button
            variant="primary"
            className="text-lg"
            disabled={createLoading}
          >
            Create monitor !
          </Button>
        </form>
      </div>

      <div className="max-w-3xl px-16 my-8 mx-auto">
        <div className="border"></div>
      </div>

      {loading && (
        <div className="max-w-3xl my-8 mx-auto text-center">
          Loading monitors...
        </div>
      )}

      {!loading && !monitorsData?.monitors?.length && (
        <div className="max-w-3xl my-8 mx-auto text-center">
          <strong>No monitor yet</strong>. You can monitor wallets by using the
          form above.
        </div>
      )}

      {!!monitorsData?.monitors?.length &&
        (monitorsData?.monitors || []).map((monitor: Monitor) => (
          <MonitorComponent key={monitor.id} monitor={monitor} />
        ))}
    </div>
  );
}

export default Home;
